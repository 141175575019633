import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
// import Countries from "../../../../static/countries.json"
import P from "../../core/P"

export const AddsForm = props => {
    // eslint-disable-next-line no-unused-vars
    const [nda, setNda] = useState(false)
    // const [countryCode, setCountryCode] = useState("")
    const [message, setMessage] = useState(undefined)
    const [status, setStatus] = useState(undefined)
    const [current, setCurrent] = useState("")
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        setCurrent(window.location.pathname)
    }, [])
    // const changeHandler = e => {
    //     setCountryCode(e.target.value)
    // }
    const onSubmit = e => {
        e.preventDefault()
        setDisabled(true)
        const form = e.target
        const data = new FormData(form)
        const GCL_ID =
            JSON.parse(localStorage.getItem("gclid")) !== null &&
                JSON.parse(localStorage.getItem("gclid")).value !== null
                ? JSON.parse(localStorage.getItem("gclid")).value
                : undefined
        data.set("countryCode", "US")
        data.set("gclid", GCL_ID)
        data.set("nda", 0)
        data.set("companyName", "")
        data.set("formType", "Add Page")
        data.set("pageURL", `https://webmobtech.com` + current)

        // eslint-disable-next-line no-unused-vars
        const response = fetch(
            //   "http://127.0.0.1:8080/api/web/contactus/contact_submit",
            "https://api.webmobtech.com/wmtblog/api/web/contactus/contact_submit",
            {
                method: "POST",
                body: data,
            }
        )
            .then(response => {
                return response.json()
            })
            .then(res => {
                if (res.meta.status === "ok") {
                    document.getElementById("contact-form").reset()
                    setNda(false)
                    window.gtag("event", "conversion", { send_to: "AW-957313161/HpgRCJL56IgDEInhvcgD" })
                    navigate("/thank-you/")
                }
                setDisabled(false)
                setMessage(res.meta.message)
                setStatus(res.meta.status)
            })
    }

    const Notification = ({ status, message }) => {
        if (!status) return null
        return (
            status && (
                <div
                    className={` rounded-md ${status === "ok" ? "bg-green-50" : "bg-red-50"
                        } p-4`}
                >
                    <div className="flex">
                        <div className="ml-3">
                            <p
                                className={`text-sm leading-5 font-medium ${status === "ok" ? "text-green-800" : "text-red-800"
                                    }`}
                            >
                                {message}
                            </p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    onClick={() => setStatus(false)}
                                    className={`inline-flex rounded-md p-1.5 ${status === "ok"
                                        ? "text-green-500 focus:bg-green-100 hover:bg-green-100"
                                        : "text-red-500 focus:bg-red-100 hover:bg-red-100"
                                        }  focus:outline-none transition ease-in-out duration-150`}
                                >
                                    <svg
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }

    return (
        <div className="bg-white p-8 lg:mx-20 md:mx-0 rounded-md">
            <P
                title={`Every Requirement is unique. Send us yours.`}
                className={`text-2xl text-shark-400`}
                noPad
            />
            <form
                id="contact-form"
                method="POST"
                className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-1 md:gap-5 lg:grid-cols-2 lg:gap-6"
                onSubmit={e => onSubmit(e)}
            >
                {status && (
                    <div className="sm:col-span-2">
                        <Notification status={status} message={message} />
                    </div>
                )}
                <div className="sm:col-span-2 mt-1 relative shadow-sm">
                        <input
                            placeholder="Name *"
                            id="fullName"
                            name="fullName"
                            required
                            className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                        />
                </div>
                <div className="lg:col-span-1 md:col-span-2 col-span-1 mt-1 relative shadow-sm">
                        <input
                            placeholder="Business Email *"
                            id="emailId"
                            name="emailId"
                            type="email"
                            required
                            className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                        />
                </div>
                <div className="lg:col-span-1 md:col-span-2 col-span-1 mt-1 relative shadow-sm">
               
                        <input
                            placeholder="Skype ID/WhatsApp No."
                            id="skypeOrWhatsapp"
                            name="skypeOrWhatsapp"
                            className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                        />
                </div>
                <div className="sm:col-span-2 flex flex-wrap">
                            <div className="mt-1 relative w-full shadow-sm">
                                <input
                                    placeholder="Mobile Phone Number *"
                                    // type="tel"
                                    id="contactNo"
                                    name="contactNo"
                                    required
                                    className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                                />
                            </div>
                </div>
                <div className="sm:col-span-2 mt-1 relative shadow-sm">
                        <textarea
                            placeholder="Your Message"
                            id="desc"
                            name="desc"
                            rows="2"
                            className="rounded-md bg-transparent form-textarea resize-none py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                        ></textarea>
                </div>
                <div className="sm:col-span-2">
                    <span className=" inline-flex shadow-sm">
                        <div className=" mt-5 inline-flex ">
                            {disabled ?
                                <button
                                    type="submit"
                                    disabled
                                    className="bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white opacity-75 cursor-not-allowed rounded"
                                >
                                    Submit
                                </button> :
                                <button
                                    type="submit"
                                    className="bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                                >
                                    Submit
                                </button>}
                        </div>
                    </span>
                </div>
            </form>
        </div>

    )
}

export default AddsForm
